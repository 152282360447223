<template>
  <section>
    <div class="ll-box">
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Chargeable Order') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'charge-table' }" >
          {{ $t('Order List') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{$route.params.id}}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>

    <!-- back button -->
    <b-button
      variant="flat-secondary"
      class="ll-cancel"
      :to="{ name: 'charge-table' }"
    >
      <!-- <feather-icon icon="RotateCcwIcon" /> -->
      <b-img
        :src="require('@/assets/images/imgs/return.png')"
        height="auto"
        class="mr-1"
      />
    </b-button>
    </div>

    <!-- content -->
    <b-row>
      <b-col
        md="6"
        lg="9"
        class="ll-p2"
      >
      <b-card
        no-body
        class="card-browser-states ll-r ll-6"
        >
          <b-card-header>
            <b-card-title>{{ $t('Charge detail') }}</b-card-title>
          </b-card-header>
          <b-card-body class="ll-cb">
            <b-table :items="items" :fields="fields" :borderless='false' :hover="true">
            </b-table>
          </b-card-body>
       </b-card>
      </b-col>
      <b-col
        md="6"
        lg="3"
        class="ll-p2"
      >
        <b-card
          no-body
          class="card-browser-states ll-c ll-6">
          <b-card-header>
            <b-card-title>{{ $t('Summary') }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-form-group
              :label="$t('Customer')"
              label-for="orderId"
              class="ll-bform"
            >
              <b-form-input
                id="orderId1"
                v-model="baseInfo.customerName"
                readonly
              />
            </b-form-group>
            <b-form-group
              :label="$t('Status')"
              label-for="orderId"
              class="ll-bform"
            >
              <b-form-input
                id="orderId2"
                v-model="baseInfo.status"
                readonly
              />
            </b-form-group>
            <b-form-group
              :label="$t('Operator')"
              label-for="mawbNo"
              class="ll-bform"
            >
              <b-form-input
                id="mawbNo"
                v-model="baseInfo.operator"
                readonly
              />
            </b-form-group>
            <b-form-group
              :label="$t('Creation Time')"
              label-for="cusOrderNo"
              class="ll-bform"
            >
              <b-form-input
                id="cusOrderNo"
                v-model="baseInfo.createdAt"
                readonly
              />
            </b-form-group>
            <b-form-group
              :label="$t('Invoice Type')"
              label-for="partyRefNo"
              class="ll-bform"
            >
              <b-form-input
                id="partyRefNo"
                v-model="baseInfo.invoiceType"
                readonly
              />
            </b-form-group>
            <!-- <b-form-group
              label="Ref No"
              label-for="numRtn"
              class="ll-bform"
            >
              <b-form-input
                id="numRtn"
                v-model="baseInfo.refNo"
                readonly
              />
            </b-form-group> -->
            <b-form-group
              :label="$t('Charge Unit')"
              label-for="sortCode"
              class="ll-bform"
            >
              <b-form-input
                id="sortCode"
                v-model="baseInfo.chargeUnit"
                readonly
              />
            </b-form-group>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BFormInput, BTable,
  BButton, BBreadcrumb, BBreadcrumbItem,
  BCardHeader, BCardTitle, BCardBody, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BTable,
    BBreadcrumb,
    BBreadcrumbItem,
    // flatPickr,
    BImg,
  },
  data() {
    return {
      items: [],
      fields: [
        { key: 'invoiceType', label: this.$t('Invoice Type') },
        { key: 'feeType', label: this.$t('Fee type') },
        { key: 'tkID', label: this.$t('TKID') },
        { key: 'amount', label: this.$t('Total Amount') },
      ],
      baseInfo: {
        customerName: '',
        status: '',
        operator: '',
        createdAt: '',
        invoiceType: '',
        refNo: '',
        chargeUnit: '',
      },
      condition: {
        page: '1',
        size: '15',
        orderBy: 'id',
        // orderMole: 0,
        status: null,
        invoiceType: null,
        beginDate: null,
        endDate: null,
        refNo: null,
        invoiceNo: null,
      },
    }
  },
  created() {},
  mounted() {
    this.getDetails()
  },
  methods: {
    getDetails() {
      // this.$http.get('invoiceDetail/getDetailById', { params: { id: this.$route.params.id } }).then(res => {
      //   // console.log(res.data.data)
      //   if (res.data.code === '10001') {
      //     this.$router.push({ name: 'auth-login' })
      //   } else if (res.data.code === '200') {
      //     this.baseInfo = res.data.data
      //     this.items = res.data.data.list
      //   }
      // })
      this.condition.refNo = this.$route.params.id
      this.$http.post('invoiceDetail/search', this.condition).then(res => {
        // console.log(res.data.data)
        const info = res.data.data.content[0]
        this.baseInfo = info
        this.baseInfo.chargeUnit = 'USD'
        const obj = {}
        obj.invoiceType = info.invoiceType
        obj.feeType = info.feeType
        obj.tkID = info.tkID
        obj.amount = info.amount
        const arr = []
        arr.push(obj)
        this.items = arr
      })
    },
  },
}
</script>
<style scoped>
.ll-input{
  width: 30%;
}
.ll-p2{
  padding: 0.5rem;
}
.ll-c{
  margin-bottom: 0;
}
.ll-c .card-header{
  font-weight: 500;
  font-size: 1.285rem;
}
.ll-c .card-body .card-title{
  font-weight: normal;
}
.d-block{
  font-size: 14px;
}
.ll-h .col{
  text-transform: uppercase;
  font-size: 0.857rem;
  letter-spacing: 0.5px;
  font-weight: bold;
}
.ll-bform{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ll-6{
  height: 340px;
  margin-bottom: 0px;
  overflow-y: auto;
}
.ll-cb{
  margin: 1.5rem;
  margin-top: 0;
}
.ll-box{
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
<style>
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th{
  background-color: #fff;
}
[dir] .table thead th{
  border: none;
  padding: 5px;
}
.d-block{
  font-size: 14px;
}
[dir] .card .card-title {
  margin-bottom: 0.5rem;
}
[dir=ltr] .b-sidebar.b-sidebar-right{
  width: 28%;
}
.d-block{
  font-size: 12px;
}
.b-sidebar-header strong{
  width: 100%;
  text-align: center;
}
.table td {
    padding: 0.72rem;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
</style>
